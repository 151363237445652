import equal from "@superblocksteam/fast-deep-equal/es6";
import { isArray, xor, isEmpty, set } from "lodash";
import moment from "moment";
import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { select, put } from "redux-saga/effects";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { setMetaProp } from "legacy/actions/metaActions";
import {
  updatePartialLayout,
  WidgetAddChild,
} from "legacy/actions/pageActions";
import EmptyDataState from "legacy/components/utils/EmptyDataState";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { WidgetPropertyValidationType } from "legacy/constants/WidgetValidation";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { WidgetIcons } from "legacy/icons/WidgetIcons";
import { APP_MODE } from "legacy/reducers/types";
import {
  getAppMode,
  getResponsiveCanvasScaleFactor,
} from "legacy/selectors/applicationSelectors";
import { getDataTreeItem } from "legacy/selectors/dataTreeSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme, TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { NUMBER_FORMATTING_OPTIONS } from "legacy/utils/FormatUtils";
import { retryPromise } from "legacy/utils/Utils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { fastClone } from "utils/clone";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetState, WidgetPropsRuntime } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import { ChartHeader } from "./ChartHeader";
import type {
  WidgetActionHook,
  CanvasWidgetsReduxState,
  WidgetActionResponse,
} from "../Factory";
import type { Data, Layout } from "plotly.js";
import type { VisualizationSpec } from "react-vega";
import type { AppState } from "store/types";
import type { View, TimeUnit as BaseTimeUnit } from "vega";
import type {
  StringFieldDef,
  SortableFieldDef,
} from "vega-lite/build/src/channeldef";
import type { Config } from "vega-lite/build/src/config";
import type { MarkDef } from "vega-lite/build/src/mark";
import type { Transform } from "vega-lite/build/src/transform";

type TimeUnit = BaseTimeUnit &
  ("yearmonth" | "yearmonthdate" | "yearmonthdatehours");

const HEADER_HEIGHT = 58;

// Hides the Vega actions until hover
const ChartWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .vega-embed summary {
    display: none;
  }

  .vega-embed:hover summary {
    display: list-item;
  }
`;

const Scaler = styled.div<{ scaleFactor: number }>`
  transform: scale(${(props) => props.scaleFactor});
  transform-origin: top left;
`;

const EmptyWrapper = styled(ChartWrapper)`
  display: flex;
  flex-direction: column;

  > div {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // center text with widget icon
  span {
    position: relative;
    top: -2px;
  }

  svg > * {
    fill: ${({ theme }) => theme.palettes.gray[7]};

    :not([opacity]) {
      fill: ${({ theme }) => theme.palettes.gray[4]};
    }
  }
`;

const PlotWrapper = styled.div`
  :hover {
    cursor: auto;
  }
`;

const EmptyChart = (props: ChartWidgetProps) => (
  <EmptyWrapper className={CLASS_NAMES.DEFAULT_CONTAINER}>
    <ChartHeader title={props.headerTitle} headerProps={props.headerProps} />
    <div>
      <WidgetIcons.CHART_WIDGET />
      <span>No data</span>
    </div>
  </EmptyWrapper>
);

const LazyVega = lazy(() =>
  retryPromise(() => import(/* webpackChunkName: "vega" */ "./VegaDefault")),
);

const vegaActions = {
  source: false,
  compiled: false,
};

const LazyPlot = lazy(() =>
  retryPromise(
    () => import(/* webpackChunkName: "plotly" */ "./PlotlyDefault"),
  ),
);

const sanitizeFieldName = (field: string): string =>
  field.replace(/\.|\[|\]/g, "_");

function getEncodingFields(props: ChartWidgetProps): string[] {
  return [
    props.groupBy.field,
    props.x.field,
    ...props.y.series.map((s) => s.field),
    props.groupBy.field && props.y.series.length
      ? "superblocks_calculated_key"
      : "",
    props.y.series.length > 1 ? "superblocks_key" : "",
    props.y.series.length > 1 ? "superblocks_value" : "",
  ].filter((key) => Boolean(key));
}

// 1. columnInfo is always kept up to date with the chartData, and controls
//    what users see in the dropdown options for their chart
//
// 2. If the user has modified the chartData bindings we need to re-initialize
function setColumnInfo(props: ChartWidgetProps) {
  if (isEmpty(props.chartData)) {
    return;
  }

  let newColumns: string[] = [];
  let newColumnInfo: ChartWidgetProps["columnInfo"] = [];
  const chartData = props.chartData ?? [];
  if (!isArray(chartData)) {
    newColumns = [];
  } else {
    newColumns = (Object.keys(chartData?.[0] ?? {}) ?? []).sort();
    if (chartData && chartData[0]) {
      newColumnInfo = newColumns.map((key) => {
        const value = chartData[0][key];
        const base = { name: sanitizeFieldName(key) };
        const defaultType = typeof value;
        if (defaultType === "number" || defaultType === "boolean") {
          return { ...base, type: defaultType, canBeNumeric: true };
        }
        if (defaultType === "string") {
          if (moment(value as string, moment.ISO_8601).isValid()) {
            return { ...base, type: "date", canBeNumeric: false };
          }
          const canBeNumeric = Number.isFinite(parseFloat(value as string));
          return { ...base, type: defaultType, canBeNumeric };
        }
        return base;
      });
    }
  }
  const currentColumnNames = (props.columnInfo ?? []).map((c) =>
    sanitizeFieldName(c.name),
  );

  if (props.shouldReinitialize) {
    // Set initial column assignments
    const numericFields = newColumnInfo.filter(({ type }) => type === "number");
    const dateFields = newColumnInfo.filter(({ type }) => type === "date");
    const stringFields = newColumnInfo.filter(({ type }) => type === "string");
    const batchedChanges: Record<string, unknown> = {};

    if (dateFields.length) {
      batchedChanges["x.field"] = dateFields[0].name;
      if (numericFields.length) {
        numericFields.forEach((info, index) => {
          batchedChanges[`y.series[${index}]`] = {
            field: info.name,
          };
        });
      }
    } else if (stringFields.length) {
      batchedChanges["x.field"] = stringFields[0].name;
      if (numericFields.length) {
        numericFields.forEach((info, index) => {
          batchedChanges[`y.series[${index}]`] = {
            field: info.name,
          };
        });
      }
    } else if (numericFields.length) {
      if (numericFields[0]?.name) {
        batchedChanges["x.field"] = numericFields[0].name;
      }
      numericFields.slice(1).forEach((info, index) => {
        batchedChanges[`y.series[${index}]`] = {
          field: info.name,
        };
      });
    }

    // If the groupBy field is no longer in the data, remove it
    if (props.groupBy.field && !newColumns.includes(props.groupBy.field)) {
      batchedChanges["groupBy.field"] = undefined;
    }
    return { columnInfo: newColumnInfo, ...batchedChanges };
  } else {
    const changedColumns = xor(newColumns, currentColumnNames);
    if (changedColumns.length) {
      const batchedChanges: Record<string, unknown> = {
        columnInfo: newColumnInfo,
      };
      if (props.groupBy.field && !newColumns.includes(props.groupBy.field)) {
        batchedChanges["groupBy.field"] = undefined;
      }
      if (!newColumns.includes(props.x.field)) {
        batchedChanges["x.field"] = undefined;
      }

      return batchedChanges;
    }
  }
}

function getChartSizeInfo(props: ChartWidgetProps) {
  const { componentHeight, componentWidth } = getComponentDimensions(props);
  const headerHeightPx = !isEmpty(props.headerTitle) ? HEADER_HEIGHT : 0;

  const chartHeight = componentHeight - headerHeightPx;
  const chartWidth = componentWidth;

  return { headerHeight: headerHeightPx, chartHeight, chartWidth };
}

export default class ChartWidget extends BaseWidget<
  ChartWidgetProps,
  WidgetState
> {
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      headerTitle: VALIDATION_TYPES.TEXT,
      chartData: VALIDATION_TYPES.TABLE_DATA,
      chartType: VALIDATION_TYPES.TEXT,
      plotlyChartJson: VALIDATION_TYPES.PLOTLY_CHART_JSON,
      plotlyChartLayout: VALIDATION_TYPES.OBJECT,
      "x.field": VALIDATION_TYPES.TEXT,
      // TODO: display nested props correctly in evaludated pop up
      // "y.minimumFractionDigits": VALIDATION_TYPES.FRACTION_DIGITS,
      // "y.maximumFractionDigits": VALIDATION_TYPES.FRACTION_DIGITS,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Adds a header to the top of the chart",
            propertyName: "headerTitle",
            label: "Header",
            controlType: "INPUT_TEXT",
            placeholderText: "Chart name",
            defaultValue: "Chart name",
            visibility: "SHOW_NAME",
            isRemovable: true,
            inputType: "text",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "headerProps",
            propertyNameForHumans: "Header",
            hiddenIfPropertyNameIsNullOrFalse: "headerTitle",
          }),
          {
            helpText: "Chart definition",
            propertyName: "chartDefinition",
            label: "Definition",
            controlType: "RADIO_BUTTON",
            defaultValue: "ui",
            forceVertical: true,
            options: [
              {
                name: "UI",
                value: "ui",
                docLink:
                  "https://docs.superblocks.com/components/charts#convert-sql-queries-to-charts",
                docLabel: "View UI Charts Docs",
              },
              {
                name: "Plotly",
                value: "plotly",
                //TODO: change this to superblock docs
                docLink:
                  "https://docs.superblocks.com/components/charts#advanced-charting-using-python-pandas-and-plotly",
                docLabel: "View Plotly Charts Docs",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Plotly chart JSON",
            propertyName: "plotlyChartJson",
            label: "Plotly chart JSON",
            placeholderText: '{"data":..., "layout":...}',
            controlType: "INPUT_TEXT",
            inputType: "TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) => !isDefinition(props, "plotly"),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Plotly chart layout JSON",
            propertyName: "plotlyChartLayout",
            label: "Layout",
            forceVertical: true,
            placeholderText: '{ "margin": {...} ... }',
            controlType: "INPUT_TEXT",
            inputType: "TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) => !isDefinition(props, "plotly"),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Populates the chart with the data",
            propertyName: "chartData",
            placeholderText: 'Enter [{ "x": "val", "y": "val" }]',
            label: "Data",
            forceVertical: true,
            controlType: "INPUT_TEXT",
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Changes the visualization of the chart data",
            propertyName: "chartType",
            label: "Visualization",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Line chart",
                value: "LINE_CHART",
              },
              {
                label: "Bar chart",
                value: "BAR_CHART",
              },
              {
                label: "Column chart",
                value: "COLUMN_CHART",
              },
              {
                label: "Area chart",
                value: "AREA_CHART",
              },
              {
                label: "Scatter chart",
                value: "SCATTER_CHART",
              },
              {
                label: "Pie chart",
                value: "PIE_CHART",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "x.field",
            label: "X-axis values",
            controlType: "CHART_FIELD_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) =>
              !isDefinition(props, "ui") || isNonXYChart(props),
            propertyCategory: PropsPanelCategory.Content,
          },

          {
            propertyName: "y",
            label: "Y-axis values",
            controlType: "CHART_MULTI_SERIES",
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) =>
              !isDefinition(props, "ui") || isNonXYChart(props),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "y.aggregation",
            label: "Y-aggregation",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "None",
                value: undefined,
              },
              {
                label: "Sum",
                value: "sum",
              },
              {
                label: "Count",
                value: "count",
              },
            ],
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) =>
              !isDefinition(props, "ui") || isNonXYChart(props),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "size.field",
            label: "Size field",
            controlType: "CHART_FIELD_SELECTOR",
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) =>
              !isDefinition(props, "ui") || isXYChart(props),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "groupBy.field",
            label: "Group by",
            controlType: "CHART_FIELD_SELECTOR",
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection(),
      {
        sectionName: "X Axis Styles",
        sectionCategory: PropsPanelCategory.Appearance,
        hidden: (props: ChartWidgetProps) =>
          !isDefinition(props, "ui") || isNonXYChart(props),
        isDefaultOpen: false,
        children: [
          {
            propertyName: "x.axisTitle",
            label: "X-axis label",
            controlType: "INPUT_TEXT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: isNonXYChart,
          },
          {
            propertyName: "x.dataType",
            label: "X-axis type",
            controlType: "DROP_DOWN",
            hidden: isNonXYChart,
            isBindProperty: false,
            isTriggerProperty: false,
            options: [
              {
                label: "Auto",
                value: "auto",
              },
              {
                label: "Time",
                value: "temporal",
              },
              {
                label: "Categorical",
                value: "nominal",
              },
              {
                label: "Continuous",
                value: "quantitative",
              },
            ],
          },
          {
            propertyName: "x.timeUnit",
            label: "X time granularity",
            controlType: "DROP_DOWN",
            hidden: (props: ChartWidgetProps) => !isTemporalXYChart(props),
            isBindProperty: false,
            isTriggerProperty: false,
            options: [
              {
                label: "Auto",
                value: undefined,
              },
              {
                label: "Monthly",
                value: "yearmonth",
              },
              {
                label: "Weekly",
                value: "yearweek",
              },
              {
                label: "Daily",
                value: "yearmonthdate",
              },
              {
                label: "Hourly",
                value: "yearmonthdatehours",
              },
              {
                label: "Day of week",
                value: "day",
              },
              {
                label: "Hour of day",
                value: "hours",
              },
              {
                label: "Month of year",
                value: "month",
              },
            ],
          },
        ],
      },
      {
        sectionName: "Y Axis Styles",
        sectionCategory: PropsPanelCategory.Appearance,
        hidden: (props: ChartWidgetProps) =>
          !isDefinition(props, "ui") || isNonXYChart(props),
        isDefaultOpen: false,
        children: [
          {
            propertyName: "y.axisTitle",
            label: "Y-axis label",
            controlType: "INPUT_TEXT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: isNonXYChart,
          },
          {
            propertyName: "y.dataType",
            label: "Y-axis type",
            controlType: "DROP_DOWN",
            hidden: isNonXYChart,
            isBindProperty: false,
            isTriggerProperty: false,
            options: [
              {
                label: "Number",
                value: "number",
              },
              {
                label: "Currency",
                value: "currency",
              },
            ],
          },
          {
            propertyName: "y.currency",
            label: "ISO Currency Code",
            controlType: "DROP_DOWN",
            hidden: (props: ChartWidgetProps) =>
              props.y.dataType !== "currency",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            defaultValue: "USD",
            options: CurrencyList.map((item) => ({
              label: item,
              value: item,
            })),
          },
          {
            propertyName: "y.notation",
            label: "Number formatting",
            controlType: "DROP_DOWN",
            hidden: (props: ChartWidgetProps) =>
              props.y.dataType !== "number" && props.y.dataType !== "currency",
            isBindProperty: false,
            isTriggerProperty: false,
            defaultValue: "standard",
            options: NUMBER_FORMATTING_OPTIONS,
          },
          {
            propertyName: "y.minimumFractionDigits",
            label: "Min fractional digits",
            controlType: "INPUT_TEXT",
            hidden: (props: ChartWidgetProps) =>
              props.y.dataType !== "number" && props.y.dataType !== "currency",
            isBindProperty: true,
            isTriggerProperty: false,
            placeholderText: "Input a number between 0-20",
          },
          {
            propertyName: "y.maximumFractionDigits",
            label: "Max fractional digits",
            controlType: "INPUT_TEXT",
            hidden: (props: ChartWidgetProps) =>
              props.y.dataType !== "number" && props.y.dataType !== "currency",
            isBindProperty: true,
            isTriggerProperty: false,
            placeholderText: "Input a number between 0-20",
          },
        ],
      },
      {
        sectionName: "Event handlers",
        sectionCategory: PropsPanelCategory.EventHandlers,
        isDefaultOpen: false,
        hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
        children: [getPopoverConfig("onSelectData", "")],
      },
    ];
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedData: [],
    };
  }

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<
      DataTree | UpdateWidgetPropertiesPayload | WidgetAddChild
    >;
  }) {
    const { widgetId, widgets, action } = params;
    if (widgets[widgetId].type !== WidgetTypes.CHART_WIDGET) {
      return;
    }
    const updates: WidgetActionResponse = [];
    const widget = widgets[widgetId] as Readonly<ChartWidgetProps>;

    switch (action.type) {
      case ReduxActionTypes.WIDGET_CREATE: {
        // Do not handle widget create for anything but the current widget
        if ((action.payload as WidgetAddChild).newWidgetId !== widgetId) {
          return;
        }
        if (
          (action.payload as WidgetAddChild).type !== WidgetTypes.CHART_WIDGET
        ) {
          // Don't re-initialize for non-charts
          break;
        }
        // Wait for the initial data to be evaluated
        yield put(setMetaProp(widgetId, "shouldReinitialize", true));
        break;
      }
      case updateWidgetProperties.type: {
        // Don't update a different chart
        if (
          !updateWidgetProperties.match(action) ||
          action.payload.widgetId !== widgetId
        ) {
          return;
        }
        if (!widget.shouldReinitialize && action.payload.updates.chartData) {
          // Set this flag when the user updates the chartData property
          yield put(setMetaProp(widgetId, "shouldReinitialize", true));
        }
        break;
      }
      case ReduxActionTypes.TREE_WILL_UPDATE: {
        const mode: APP_MODE = yield select(getAppMode);
        if (mode !== APP_MODE.EDIT) {
          break;
        }

        const previouslyEvaluatedWidget: ChartWidgetProps | undefined =
          yield select(getDataTreeItem, widgetId);

        // In the case of a rename that happens during an eval, the name will be the new name of the widget and that name will not be in the data tree
        // the rename will cause an evaluation of the widget which will then trigger this on the second evaluation
        const name = widgets[widgetId].widgetName;
        const evaluatedWidget = (action.payload as DataTree).PAGE[
          name
        ] as unknown as ChartWidgetProps;

        if (!evaluatedWidget) {
          break;
        }

        const chartDataModified = !equal(
          evaluatedWidget.chartData,
          previouslyEvaluatedWidget?.chartData,
        );
        if (chartDataModified) {
          const updates = setColumnInfo(evaluatedWidget);
          if (updates) {
            const updatedWidget = fastClone(widget);
            Object.entries(updates).forEach(([path, update]) => {
              set(updatedWidget, path, update);
            });
            yield put(setMetaProp(widgetId, "shouldReinitialize", false));
            yield put(updatePartialLayout({ [widgetId]: updatedWidget }));
          }
        }

        break;
      }
    }
    updates.push({ widgetId, widget });
    return updates;
  };

  private vegaView: View | undefined;

  stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  preventDefault = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  getPlotlyView() {
    if (
      !this.props.isLoading &&
      (isEmpty(this.props.plotlyChartJson) ||
        isEmpty(this.props.plotlyChartJson.data))
    ) {
      return <EmptyChart {...this.props} />;
    }

    const { chartHeight, chartWidth } = getChartSizeInfo(this.props);
    let mutableLayout = this.props.plotlyChartJson?.layout
      ? fastClone(this.props.plotlyChartJson.layout ?? {})
      : {};
    if (this.props.plotlyChartJson?.layout) {
      mutableLayout.height = chartHeight;
      mutableLayout.width = chartWidth;
      if (!isEmpty(this.props.plotlyChartLayout)) {
        mutableLayout = Object.assign(
          {},
          mutableLayout,
          fastClone(this.props.plotlyChartLayout),
        );
      }
    }

    const mutableData = fastClone(this.props.plotlyChartJson?.data);

    return (
      <ChartWrapper className={CLASS_NAMES.DEFAULT_CONTAINER}>
        <ChartHeader
          title={this.props.headerTitle}
          headerProps={this.props.headerProps}
        />
        <Suspense fallback={<Skeleton />}>
          <PlotWrapper
            className={this.props.isLoading ? "bp5-skeleton" : ""}
            onMouseDown={this.stopPropagation}
            onMouseOver={this.preventDefault}
          >
            <LazyPlot data={mutableData} layout={mutableLayout || {}} />
          </PlotWrapper>
        </Suspense>
      </ChartWrapper>
    );
  }

  getPageView() {
    if (!this.props.chartType) {
      return <EmptyDataState />;
    }

    if (this.props.chartDefinition === "plotly") {
      return this.getPlotlyView();
    }

    if (!this.props.isLoading && isEmpty(this.props.chartData)) {
      return <EmptyChart {...this.props} />;
    }

    if (
      isXYChart(this.props) &&
      !this.props.x.field &&
      !this.props.y.series?.length
    ) {
      return <EmptyDataState />;
    }
    if (isNonXYChart(this.props) && !this.props.size.field) {
      return <EmptyDataState />;
    }

    const { chartHeight, chartWidth } = getChartSizeInfo(this.props);
    // Vega's user interaction doesn't play well with canvas scaling, so we
    // scale the vega chart component by the inverser of the canvas scale factor
    // with CSS and then let Vega handle the scaling by passing in a scaled
    // width and height.
    const scaleFactor = this.props.canvasScaleFactor;

    return (
      <ChartWrapper className={CLASS_NAMES.DEFAULT_CONTAINER}>
        <ChartHeader
          title={this.props.headerTitle}
          headerProps={this.props.headerProps}
        />

        <Suspense fallback={<Skeleton />}>
          <Scaler scaleFactor={1 / scaleFactor}>
            <LazyVega
              className={this.props.isLoading ? "bp5-skeleton" : ""}
              renderer="canvas"
              height={chartHeight * scaleFactor}
              width={chartWidth * scaleFactor}
              spec={this.getSpec()}
              onNewView={(view) => {
                if (this.vegaView) {
                  this.vegaView.removeSignalListener(
                    "select",
                    this.onSelectData,
                  );
                }
                this.vegaView = view;
                view.addSignalListener("select", this.onSelectData.bind(this));
              }}
              actions={vegaActions}
            />
          </Scaler>
        </Suspense>
      </ChartWrapper>
    );
  }

  getMark(): MarkDef {
    switch (this.props.chartType) {
      case "AREA_CHART":
        return {
          type: "area",
          line: {
            color: this.props.generatedTheme.colors.primary500,
            strokeWidth: 1.5,
          },
        };
      case "LINE_CHART":
        return {
          type: "line",
          strokeWidth: 1.5,
          point: { size: 40 },
        };
      case "BAR_CHART":
        return {
          type: "bar",
          cornerRadiusBottomRight: 4,
          cornerRadiusTopRight: 4,
        };
      case "COLUMN_CHART":
        return {
          type: "bar",
          stroke: this.props.generatedTheme.colors.neutral,
          cornerRadiusTopLeft: 4,
          cornerRadiusTopRight: 4,
        };
      case "PIE_CHART":
        return { type: "arc" };
      case "SCATTER_CHART":
        return { type: "point" };
    }
    return { type: "point", size: 40, filled: false };
  }

  getConfig(): Config {
    const hasColorChannel =
      this.props.y.series.length > 1 || this.props.groupBy.field;

    return {
      customFormatTypes: true,
      font: `${this.props.generatedTheme.fontFamily}, sans-serif`,
      padding: 24,
      background: this.props.generatedTheme.colors.neutral,
      axis: {
        tickWidth: 0.5,
        labelColor: this.props.generatedTheme.colors.neutral500,
        domainColor: this.props.generatedTheme.colors.neutral100,
        labelOverlap: true,
        labelFontSize: 12,
        labelPadding: 5,
        titleFontSize: 14,
        titlePadding: 10,
        gridColor: this.props.generatedTheme.colors.neutral200,
        gridDash: [4, 4],
      },
      axisY: {
        domain: false,
        gridWidth: 0.5,
        tickSize: 0,
        labelPadding: 5,
        grid: true,
      },
      legend: {
        symbolType: "circle",
        symbolSize: 80,
        labelColor: this.props.generatedTheme.colors.neutral500,
        labelFontSize: 12,
        columnPadding: 30,
        rowPadding: 12,
        labelOffset: 12,
      },
      view: { stroke: "transparent" },
      arc: {
        strokeWidth: 0,
      },
      line: {
        color: this.props.generatedTheme.colors.primary500,
      },
      point: {
        size: 40,
        filled: true,
        fill: this.props.generatedTheme.colors.neutral,
        stroke: this.props.generatedTheme.colors.primary500,
        strokeWidth: hasColorChannel ? 0 : 2,
      },
      area: {
        color: {
          x1: 1,
          y1: 1,
          x2: 1,
          y2: 0,
          gradient: "linear",
          stops: [
            {
              offset: 0,
              color: tinycolor(this.props.generatedTheme.colors.primary500)
                .setAlpha(0.04)
                .toRgbString(),
            },
            {
              offset: 1,
              color: tinycolor(this.props.generatedTheme.colors.primary500)
                .setAlpha(0.32)
                .toRgbString(),
            },
          ],
        },
      },
      bar: {
        fill: this.props.generatedTheme.colors.primary500,
      },
    };
  }

  // This function localizes the timezone of the data to the browser's timezone.
  localizeTimezone = (data: Record<string, any>[]): Record<string, any>[] => {
    try {
      return data.map((datum) => {
        // If the datum has a time field, localize it to the browser's timezone.
        if (datum[this.props.x.field]) {
          const date = new Date(datum[this.props.x.field]);
          const localizedDate = new Date(
            date.getTime() + date.getTimezoneOffset() * 60000,
          );
          datum[this.props.x.field] = localizedDate.toISOString();
        }
        return datum;
      });
    } catch (e) {
      // If there is an error, just return the data as is.
      console.error("Error localizing timezone: ", e);
      return data;
    }
  };

  getSpec(): VisualizationSpec {
    const data = fastClone(this.props.chartData);
    // TODO: look into why sometimes `this.props.chartData` comes in string format
    const sanitizedData = isArray(data)
      ? data.map((el) => {
          const sanitized: Record<string, any> = {};
          Object.entries(el).forEach(([key, val]) => {
            if (typeof key === "string")
              sanitized[sanitizeFieldName(key)] = val;
          });
          return sanitized;
        })
      : data;

    let localizedSanitizedData = sanitizedData;
    if (isTemporalXYChart(this.props)) {
      localizedSanitizedData = this.localizeTimezone(sanitizedData);
    }
    const { chartType, x, y } = this.props;
    const usedFields = getEncodingFields(this.props);
    const usedFieldsSanitized = usedFields.map((field) =>
      sanitizeFieldName(field),
    );
    const hasMultipleSeries = y.series.length > 1;

    const baseSpec: VisualizationSpec = {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      autosize: {
        type: "fit",
        contains: "padding",
      },
      mark: this.getMark(),
      data: { values: localizedSanitizedData },
      params: [
        {
          name: "select",
          select: { type: "point", fields: usedFieldsSanitized },
        },
      ],
      encoding: {
        ...(this.props.groupBy.field
          ? {
              color: {
                field: this.props.groupBy.field,
                type: "nominal",
                legend: { title: null },
              },
            }
          : {}),
        opacity: {
          condition: [{ param: "select", value: 1 }],
          value: 0.2,
        },
      },
      config: this.getConfig(),
    };

    if (isNonXYChart(this.props)) {
      return {
        ...baseSpec,
        mark: { ...(baseSpec.mark as MarkDef), tooltip: true },
        encoding: {
          ...baseSpec.encoding,
          theta: {
            field: this.props.size.field,
            type: "quantitative",
            aggregate: "sum",
          },
        },
      };
    } else {
      const primaryDimension = {
        ...getPrimaryDimensionMeta(this.props),
        field: x.field,
      };

      const secondaryDimension = {
        ...(y.aggregation ? { aggregate: y.aggregation } : {}),
        type: "quantitative",
        field: hasMultipleSeries ? "superblocks_value" : y.series[0]?.field,
        title: hasMultipleSeries ? "value" : y.series[0]?.field,
      } as const;

      const hasColorChannel = hasMultipleSeries || this.props.groupBy.field;
      const groupBy = hasColorChannel
        ? ({
            field:
              hasMultipleSeries && this.props.groupBy.field
                ? "superblocks_calculated_key"
                : hasMultipleSeries
                ? "superblocks_key"
                : this.props.groupBy.field,
            type: "nominal",
            title: this.props.groupBy.field ? "Group by" : "Series",
          } as const)
        : {};

      let tooltip: any = [primaryDimension, secondaryDimension, groupBy].filter(
        (dimension) => !isEmpty(dimension),
      );

      if (primaryDimension.timeUnit === "yearweek") {
        tooltip = tooltip.filter((t: any) => t.timeUnit !== "yearweek");
        const tooltipReplacement: StringFieldDef<string> = {
          format: "%b %d, %Y",
          type: "temporal",
          title: `${primaryDimension.field} (year-month-date)`, // same title format as yearmonthdate
        };
        if (secondaryDimension.aggregate) {
          tooltipReplacement.field = `yearweek_${primaryDimension.field}`;
        } else {
          tooltipReplacement.field = "superblocks_year_month_date";
        }
        tooltip.push(tooltipReplacement);
      }

      const transform: Transform[] = hasMultipleSeries
        ? [
            {
              fold: y.series
                .map(({ field }) => field)
                .filter((y) => Boolean(y)),
              as: ["superblocks_key", "superblocks_value"],
            },
            ...(this.props.groupBy.field
              ? [
                  {
                    calculate: `toString(datum['${this.props.groupBy.field}']) + ' ' + toString(datum.superblocks_key)`,
                    as: "superblocks_calculated_key",
                  },
                ]
              : []),
          ]
        : [];

      if (
        primaryDimension.timeUnit === "yearweek" &&
        !secondaryDimension.aggregate
      ) {
        transform.push({
          timeUnit: "yearmonthdate",
          field: primaryDimension.field,
          as: "superblocks_year_month_date",
        });
      }

      return {
        ...baseSpec,
        transform,
        encoding: {
          ...baseSpec.encoding,
          [chartType === "BAR_CHART" ? "y" : "x"]: {
            ...primaryDimension,
            // Use the order from the user's data, not alphabetical order
            ...(primaryDimension.type === "nominal" ? { sort: null } : {}),
            axis: {
              title: x.axisTitle,
              ...(primaryDimension.type === "temporal" &&
              primaryDimension.timeUnit === "yearmonth"
                ? { tickCount: "month" }
                : {}),
              ...(primaryDimension.timeUnit === "yearweek"
                ? { format: "%b %d, %Y", tickCount: "week" } // same as yearmonthdate
                : {}),
              labelAngle: 0,
              grid: chartType !== "BAR_CHART" && chartType !== "COLUMN_CHART",
              gridDash: false,
              gridColor: this.props.generatedTheme.colors.neutral100,
              titleColor: this.props.generatedTheme.colors.neutral500,
            },
            ...(isTemporalXYChart(this.props)
              ? getTimeScale(this.props.x.timeUnit)
              : {}),
          },
          [chartType === "BAR_CHART" ? "x" : "y"]: {
            ...secondaryDimension,
            axis: {
              title: y.axisTitle,
              tickCount: 5,
              format: {
                dataType: this.props.y.dataType,
                notation: this.props.y.notation,
                minimumFractionDigits: this.props.y.minimumFractionDigits,
                maximumFractionDigits: this.props.y.maximumFractionDigits,
                currency: this.props.y.currency,
              },
              formatType: "dataFormat",
              titleColor: this.props.generatedTheme.colors.neutral500,
            },
          },
          ...(hasColorChannel
            ? { color: { ...groupBy, legend: { title: null } } }
            : {}),
          tooltip,
        },
      };
    }
  }

  onSelectData(
    signal: string,
    row: { vlPoint: { or: Record<string, Record<string, unknown>> } } & Record<
      string,
      unknown[]
    >,
  ) {
    try {
      if (!Object.keys(row).length) {
        if (this.props.selectedData && this.props.selectedData.length > 0) {
          this.props.updateWidgetMetaProperty("selectedData", []);
        }
        return;
      }
      const possibleRows = Object.values(row.vlPoint.or);
      const outputRows = possibleRows.map((possible) => {
        const output: Record<string, unknown> = {};
        if (possible.superblocks_key) {
          output[possible.superblocks_key as string] =
            possible.superblocks_value;
        } else if (this.props.y.series.length === 1) {
          output[this.props.y.series[0].field] =
            possible[this.props.y.series[0].field];
        }
        if (this.props.x.field) {
          output[this.props.x.field] = possible[this.props.x.field];
        }
        if (this.props.groupBy.field) {
          output[this.props.groupBy.field] = possible[this.props.groupBy.field];
        }
        if (this.props.size.field) {
          output[this.props.size.field] = possible[this.props.size.field];
        }
        return output;
      });
      this.props.updateWidgetMetaProperty("selectedData", outputRows);
      if (this.props.onSelectData) {
        super.runEventHandlers({
          steps: this.props.onSelectData,
          type: EventType.ON_SELECT_DATA,
        });
      }
    } catch (e) {
      // Noop
      console.log(e);
    }
  }

  getWidgetType(): WidgetType {
    return "CHART_WIDGET";
  }
}

export interface ChartWidgetProps extends WidgetPropsRuntime, WithMeta {
  headerTitle?: string;
  chartData: Array<Record<string, unknown>>;
  chartType: string;
  isVisible?: boolean;
  x: {
    field: string;
    dataType: "auto" | "temporal" | "quantitative" | "ordinal" | "nominal";
    timeUnit?: TimeUnit;
    axisTitle: string;
  };
  y: {
    axisTitle: string;
    dataType: "number" | "currency";
    notation?: Intl.NumberFormatOptions["notation"];
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    currency?: string;
    aggregation?: "sum" | "count";
    series: Array<{
      field: string;
    }>;
  };
  groupBy: {
    field: string;
  };
  size: {
    field: string;
  };
  // Flag set from the point that chartData property is modified until we get
  // the first complete data back from the server.
  shouldReinitialize?: boolean;
  columnInfo?: Array<{
    name: string;
    type?: "number" | "string" | "date" | "boolean";
    canBeNumeric?: boolean;
  }>;
  chartDefinition: string;
  plotlyChartJson: {
    data: Data[];
    layout?: Partial<Layout>;
  };
  plotlyChartLayout: any;
  canvasScaleFactor: number;
  selectedData: Array<Record<string, unknown>>; // TODO(MH): Verify this type
  onSelectData?: MultiStepDef;
  // style properties
  headerProps?: {
    textStyle: TextStyleWithVariant;
  };
  // from state
  generatedTheme: GeneratedTheme;
}

function isXYChart(props: ChartWidgetProps) {
  return props && props.chartType !== "PIE_CHART";
}

function isNonXYChart(props: ChartWidgetProps) {
  return !isXYChart(props);
}

function isTemporalXYChart(props: ChartWidgetProps) {
  return (
    props &&
    props.chartType !== "PIE_CHART" &&
    (props.x.dataType === "temporal" ||
      (props.x.dataType === "auto" &&
        props.columnInfo?.find(
          (col) => col.name === props.x.field && col.type === "date",
        )))
  );
}

function isDefinition(props: ChartWidgetProps, chartDefinition: string) {
  return (
    (props.chartDefinition === undefined && chartDefinition === "ui") ||
    props.chartDefinition === chartDefinition
  );
}

function getPrimaryDimensionMeta(
  props: ChartWidgetProps,
): Partial<SortableFieldDef<string>> {
  const dataType = props.x.dataType;
  const columnInfo = props.columnInfo?.find(
    (col) => col.name === props.x.field,
  );

  if (isTemporalXYChart(props)) {
    if (
      props.x.timeUnit === "day" ||
      props.x.timeUnit === "hours" ||
      props.x.timeUnit === "month"
    ) {
      // This is a recommended workaround for non-continuous time units
      // We aren't using "nominal" because we actually want Vega-Lite to apply sorting
      // https://vega.github.io/vega-lite/docs/timeunit.html#time-unit-with-ordinal-fields
      return { type: "ordinal", timeUnit: props.x.timeUnit };
    }
    return { type: "temporal", timeUnit: props.x.timeUnit };
  }

  if (dataType === "auto") {
    if (columnInfo?.type === "number" || columnInfo?.canBeNumeric) {
      return { type: "quantitative" };
    }
    return { type: "nominal" };
  }

  if (dataType === "quantitative") {
    return { type: "quantitative" };
  }

  return {};
}

function getTimeScale(timeUnit: TimeUnit | undefined) {
  if (!timeUnit) {
    return {};
  }
  switch (timeUnit) {
    case "hours":
      return { scale: { domain: Array.from({ length: 24 }, (_, i) => i) } };
    case "day":
      return { scale: { domain: Array.from({ length: 7 }, (_, i) => i) } };
    case "month":
      return { scale: { domain: Array.from({ length: 12 }, (_, i) => i + 1) } };
  }
  return {};
}

const mapStateToProps = (state: AppState) => {
  return {
    canvasScaleFactor: getResponsiveCanvasScaleFactor(state),
    generatedTheme: selectGeneratedTheme(state),
  };
};

export const ConnectedChartWidget = connect(mapStateToProps)(
  withMeta(ChartWidget),
);
