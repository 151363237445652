import React, { Suspense } from "react";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";

import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import log from "utils/logger";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import withMeta from "../withMeta";
import IFrameComponent from "./IFrameComponent";

class IFrameWidget extends BaseWidget<IFrameWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "source",
            helpText: "Set the URL to display.",
            label: "Source URL",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter URL",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "srcDoc",
            helpText: "Inline HTML to embed (Overrides URL)",
            label: "Source doc",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter HTML",
            forceVertical: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection(),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onMessageReceived",
            "Triggers an action when a message event is received",
          ),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      source: VALIDATION_TYPES.URL_OR_BASE64,
      srcDoc: VALIDATION_TYPES.TEXT,
    };
  }

  handleMessageReceive = (event: MessageEvent) => {
    try {
      const steps = (this.props.onMessageReceived ?? [])?.flatMap((step) => {
        return step;
      });
      super.runEventHandlers({
        steps,
        type: EventType.ON_IFRAME_MESSAGE_RECEIVED,
        additionalNamedArguments: { currentMessage: event.data },
      });
    } catch (error) {
      log.debug("Error parsing onMessageReceived action");
      log.debug(error);
    }
  };

  getPageView() {
    const { widgetId, source, srcDoc, isLoading } = this.props;
    return (
      <Suspense fallback={<Skeleton />}>
        <IFrameComponent
          widgetId={widgetId}
          source={source}
          srcDoc={srcDoc}
          isLoading={isLoading}
          onMessageReceived={this.handleMessageReceive}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return WidgetTypes.IFRAME_WIDGET;
  }
}

export interface IFrameWidgetProps extends WidgetPropsRuntime {
  source: string;
  srcDoc?: string;
  onMessageReceived?: MultiStepDef;
}

export default IFrameWidget;
export const ConnectedIFrameWidget = withMeta(IFrameWidget);
