import { Classes as BlueprintClasses } from "@blueprintjs/core";
import { css } from "styled-components";
import { generatePaddingCss } from "legacy/widgets/base/generatePaddingStyle";
import { CLASS_NAMES } from "../classnames";
import { BUTTON_PADDING } from "../constants";
import { GeneratedTheme } from "../types";
import {
  styleFromStyleBlock,
  disabledStyles,
  iconStyleFromStyleBlock,
  menuItemStyleFromBlock,
  inputStyleFromStyleBlock,
  hoverStyles,
  activeStyles,
  defaultStyles,
} from "./utils";

const getCaretIcon = (fill: string) => {
  return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' fill='${encodeURIComponent(
    fill,
  )}'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5a1 1 0 0 0-.7.3L8 8.6 4.7 5.3a1 1 0 0 0-1.4 1.4l4 4c.2.2.4.3.7.3s.5-.1.7-.3l4-4c.2-.2.3-.4.3-.7 0-.5-.4-1-1-1z' /%3E%3C/svg%3E")`;
};

export const generateDropdownStyles = (theme: GeneratedTheme) => {
  const dropdownStyles = theme.dropdown;
  return css`
    .${CLASS_NAMES.TAG_INPUT} {
      .${BlueprintClasses.TAG}.${BlueprintClasses.MINIMAL}.${BlueprintClasses.INTERACTIVE} {
        ${styleFromStyleBlock(dropdownStyles.tag)}
      }
      &.${CLASS_NAMES.DISABLED_MODIFIER} {
        .${BlueprintClasses.TAG}.${BlueprintClasses.MINIMAL} {
          ${disabledStyles(dropdownStyles.tag)}
        }
      }
    }
    .${CLASS_NAMES.DROPDOWN_CLEAR_ICON} {
      ${iconStyleFromStyleBlock(dropdownStyles.closeIcon)}
    }

    .${CLASS_NAMES.CARET_ICON}.${BlueprintClasses.ACTIVE}::before {
      content: ${getCaretIcon(
        dropdownStyles.caratIcon.iconColor.active ??
          dropdownStyles.caratIcon.iconColor.default,
      )};
    }
    .${CLASS_NAMES.CARET_ICON}::before {
      content: ${getCaretIcon(dropdownStyles.caratIcon.iconColor.default)};
    }

    .${BlueprintClasses.POPOVER} .${BlueprintClasses.POPOVER_CONTENT} {
      border-radius: unset;
      background: none;
    }
    .${BlueprintClasses.MENU} {
      background: unset;
      color: unset;
      border-radius: unset;
    }

    .${CLASS_NAMES.DROPDOWN}
      .${BlueprintClasses.POPOVER_CONTENT}
      .${BlueprintClasses.MENU}
      .${BlueprintClasses.MENU_ITEM} {
      ${menuItemStyleFromBlock(dropdownStyles.menuItem)}
    }

    .${CLASS_NAMES.MENU_ITEM} {
      ${menuItemStyleFromBlock(dropdownStyles.menuItem)}
    }

    .${CLASS_NAMES.DROPDOWN}.ant-select {
      .ant-select-selection-item {
        ${defaultStyles(theme.inputs.input)}
      }
      .ant-select-selector {
        ${inputStyleFromStyleBlock(theme.inputs.input)}
        ${generatePaddingCss(BUTTON_PADDING)};
      }
      &:hover {
        .ant-select-selector {
          ${hoverStyles(theme.inputs.input)}
        }
      }
      &:focus,
      &:active,
      &.ant-select-focused {
        .ant-select-selector:not(.ant-select-disabled) {
          ${activeStyles(theme.inputs.input)}
        }
      }
    }
  `.join("");
};
