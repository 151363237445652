import { ApplicationScope } from "@superblocksteam/shared";
import React from "react";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { Flag, Flags } from "store/slices/featureFlags";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import withMeta from "../withMeta";
import { SwitchComponentWithLayoutManaged } from "./SwitchComponent";
import {
  DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  LabelPosition,
} from "./constants";
import type { DerivedPropertiesMap } from "../Factory";
import type { SwitchWidgetProps } from "./types";

class SwitchWidget extends BaseWidget<SwitchWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            helpText: "The label to be displayed besides the switch",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "labelProps",
            propertyNameForHumans: "Label",
            hiddenIfPropertyNameIsNullOrFalse: "label",
          }),
          {
            propertyName: "labelPosition",
            label: "Label position",
            controlType: "RADIO_BUTTON",
            isJSConvertible: true,
            radioType: "BUTTON_GROUP",
            helpText: "The position of the label",
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: "right",
            options: [
              {
                name: "Left",
                value: LabelPosition.LEFT,
              },
              {
                name: "Right",
                value: LabelPosition.RIGHT,
              },
            ],
            hidden: (props) => !props.label,
            propertyCategory: PropsPanelCategory.Appearance,
          },
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
        heightSupportsFitContent: (flags: Flags) => {
          return Boolean(flags[Flag.ENABLE_TYPOGRAPHY]);
        },
      }),
      {
        sectionName: "Advanced",
        children: [
          {
            propertyName: "defaultSwitchState",
            label: "Default toggled",
            helpText: "Whether the switch is toggled on by default",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "isRequired",
            label: "Required",
            helpText:
              "Requires that the switch is toggled on for form submission",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          ...visibleProperties({ useJsExpr: false }),
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onSwitchChange",
            "Triggers an action when the switch state is changed",
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      labelPosition: VALIDATION_TYPES.TEXT,
      defaultSwitchState: VALIDATION_TYPES.BOOLEAN,
      isRequired: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      isToggledOn: "defaultSwitchState",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{this.isToggledOn}}`,
      isValid: `{{ this.isRequired ? !!this.isToggledOn : true }}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isToggledOn: undefined,
      isTouched: false,
    };
  }

  getPageView() {
    return (
      <SwitchComponentWithLayoutManaged
        {...this.props}
        onSwitchChange={this.onSwitchChange}
      />
    );
  }

  onSwitchChange = (isToggledOn: boolean) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    this.props.updateWidgetMetaProperty(
      "isToggledOn",
      isToggledOn,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onSwitchChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_CHECK_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.SWITCH_WIDGET;
  }
}

export default SwitchWidget;
export const ConnectedSwitchWidget = withMeta(SwitchWidget);
