import React, { Suspense } from "react";
import Skeleton from "legacy/components/utils/Skeleton";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";

import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import PDFViewerComponent from "./PDFViewerComponent";

class PDFViewerWidget extends BaseWidget<PDFViewerWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "fileUrl",
            helpText: "Set the URL/Base64 of the PDF file to display.",
            label: "HTTPS or Base64 URL",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter URL / Base64",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection(),
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      fileUrl: VALIDATION_TYPES.URL_OR_BASE64,
    };
  }

  getPageView() {
    return (
      <Suspense fallback={<Skeleton />}>
        <PDFViewerComponent
          widgetId={this.props.widgetId}
          fileUrl={this.props.fileUrl}
          isLoading={this.props.isLoading}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return "PDF_VIEWER_WIDGET";
  }
}

export interface PDFViewerWidgetProps extends WidgetPropsRuntime {
  fileUrl?: string;
}

export default PDFViewerWidget;
