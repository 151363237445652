import { ApplicationScope } from "@superblocksteam/shared";
import React from "react";
import styled from "styled-components";
import { getGlobalConfigs } from "legacy/configs";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, DEFAULT_CENTER } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { WidgetPropertyValidationType } from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import withMeta, { WithMeta } from "../withMeta";
import MapComponent from "./MapComponent";

const { google } = getGlobalConfigs();

const DisabledContainer = styled.div`
  background-color: white;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 15%;
    margin-bottom: 10%;
    color: #7c7c7c;
  }
  p {
    color: #0a0b0e;
  }
`;

const DefaultCenter = { ...DEFAULT_CENTER, long: DEFAULT_CENTER.lng };

type Center = {
  lat: number;
  long: number;
  [x: string]: any;
};
class MapWidget extends BaseWidget<MapWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "mapCenter",
            label: "Initial location",
            isJSConvertible: true,
            controlType: "LOCATION_SEARCH",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "defaultMarkers",
            label: "Default markers",
            controlType: "INPUT_TEXT",
            inputType: "ARRAY",
            helpText: "Sets the default markers on the map",
            placeholderText: 'Enter [{ "lat": "val1", "long": "val2" }]',
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "zoomLevel",
            label: "Zoom level",
            controlType: "STEP",
            helpText: "Changes the default zoom of the map",
            stepType: "ZOOM_PERCENTAGE",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection(),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [getPopoverConfig("onMarkerClick", "")],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      defaultMarkers: VALIDATION_TYPES.MARKERS,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
      isVisible: VALIDATION_TYPES.BOOLEAN,
      allowZoom: VALIDATION_TYPES.BOOLEAN,
      zoomLevel: VALIDATION_TYPES.NUMBER,
      mapCenter: VALIDATION_TYPES.LAT_LONG,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      markers: "defaultMarkers",
      center: "mapCenter",
    };
  }

  static getMetaPropertiesMap(): Record<string, undefined> {
    return {
      center: undefined,
      markers: undefined,
      selectedMarker: undefined,
    };
  }

  updateCenter = (lat: number, long: number) => {
    this.props.updateWidgetMetaProperty("center", { lat, long });
  };

  updateMarker = (lat?: number, long?: number, index?: number) => {
    const markers: Array<MarkerProps> = [...(this.props.markers || [])].map(
      (marker, i) => {
        if (index === i && lat && long) {
          marker = { lat, long };
        }
        return marker;
      },
    );
    this.disableDrag(false);
    this.props.updateWidgetMetaProperty("markers", markers);
  };

  unselectMarker = () => {
    this.props.updateWidgetMetaProperty("selectedMarker", undefined);
  };

  onMarkerClick = (
    lat: number,
    long: number,
    title: string,
    description: string,
  ) => {
    this.disableDrag(true);
    const selectedMarker = {
      lat: lat,
      long: long,
      title: title,
      description: description,
    };
    this.props.updateWidgetMetaProperty(
      "selectedMarker",
      selectedMarker,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onMarkerClick,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_MARKER_CLICK,
        entityName: this.props.widgetName,
      }),
    );
  };

  getCenter(): Center {
    return this.props.center || this.props.mapCenter || DefaultCenter;
  }

  componentDidUpdate(prevProps: MapWidgetProps) {
    //remove selectedMarker when map initial location is updated
    if (
      JSON.stringify(prevProps.center) !== JSON.stringify(this.props.center) &&
      this.props.selectedMarker
    ) {
      this.unselectMarker();
    }
  }

  getPageView() {
    return (
      <>
        {!google.enabled && (
          <DisabledContainer>
            <h1>{"Map component coming soon"}</h1>
          </DisabledContainer>
        )}
        {google.enabled && (
          <MapComponent
            apiKey={google.apiKey}
            widgetId={this.props.widgetId}
            isVisible={this.props.isVisible}
            zoomLevel={this.props.zoomLevel}
            allowZoom={this.props.allowZoom}
            center={this.getCenter()}
            enableCreateMarker={false}
            selectedMarker={this.props.selectedMarker}
            updateCenter={this.updateCenter}
            isDisabled={this.props.isDisabled}
            enableSearch={false}
            enablePickLocation={false}
            updateMarker={this.updateMarker}
            selectMarker={this.onMarkerClick}
            unselectMarker={this.unselectMarker}
            markers={this.props.markers}
            enableDrag={() => {
              this.disableDrag(false);
            }}
          />
        )}
      </>
    );
  }

  getWidgetType(): WidgetType {
    return "MAP_WIDGET";
  }
}

// Markers may optionally specify a circle around them.
export interface MarkerProps extends Partial<CircleProps> {
  lat: number;
  long: number;
  title?: string;
  description?: string; // text that should be shown in the tooltip.
  icon?: string; // URL to an icon that replaces the original.
  cluster?: boolean; // whether this marker should be clustered if it's close to another clustered marker
}

export interface CircleProps {
  lat: number;
  long: number;
  radius: number; // Radius of a circle in meters.
  fillColor?: string; // fillColor for the shape on a marker.
}

export interface MapWidgetProps extends WidgetPropsRuntime, WithMeta {
  isDisabled?: boolean;
  isVisible?: boolean;
  zoomLevel: number;
  allowZoom: boolean;
  enableSearch?: boolean;
  enablePickLocation?: boolean;
  mapCenter: {
    lat: number;
    long: number;
    title?: string;
  };
  center?: {
    lat: number;
    long: number;
  };
  defaultMarkers?: Array<MarkerProps>;
  markers?: Array<MarkerProps>;
  selectedMarker?: {
    lat: number;
    long: number;
    title?: string;
    description?: string;
  };
  onMarkerClick?: MultiStepDef;
}

export default MapWidget;
export const ConnectedMapWidget = withMeta(MapWidget);
