import { ApplicationScope } from "@superblocksteam/shared";
import React from "react";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { Flag, Flags } from "store/slices/featureFlags";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import withMeta from "../withMeta";
import { CheckboxComponentWithLayoutManaged } from "./CheckboxComponent";
import { DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT } from "./constants";
import { CheckboxWidgetProps } from "./types";
import type { DerivedPropertiesMap } from "../Factory";

class CheckboxWidget extends BaseWidget<CheckboxWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig<CheckboxWidgetProps>[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            helpText: "Displays a label next to the component",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "labelProps",
            propertyNameForHumans: "Label",
            hiddenIfPropertyNameIsNullOrFalse: "label",
          }),
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
        heightSupportsFitContent: (flags: Flags) => {
          return Boolean(flags[Flag.ENABLE_TYPOGRAPHY]);
        },
      }),
      {
        sectionName: "Advanced",
        children: [
          {
            propertyName: "defaultCheckedState",
            label: "Default checked",
            helpText:
              "Checks / un-checks the checkbox by default. Changes to the default selection update the component state",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes checking this component mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
            propertyCategory: PropsPanelCategory.Interaction,
          },
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onCheckChange",
            "Triggers an action when the check state is changed",
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      defaultCheckedState: VALIDATION_TYPES.BOOLEAN,
      isRequired: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      isChecked: "defaultCheckedState",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{this.isChecked}}`,
      isValid: `{{ this.isRequired ? !!this.isChecked : true }}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isChecked: undefined,
      isTouched: false,
    };
  }

  getPageView() {
    return (
      <CheckboxComponentWithLayoutManaged
        {...this.props}
        onCheckChange={this.onCheckChange}
      />
    );
  }

  onCheckChange = (isChecked: boolean) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    this.props.updateWidgetMetaProperty(
      "isChecked",
      isChecked,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onCheckChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_CHECK_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return "CHECKBOX_WIDGET";
  }
}

export default CheckboxWidget;
export const ConnectedCheckboxWidget = withMeta(CheckboxWidget);
