import React from "react";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget, { WidgetPropsRuntime } from "../BaseWidget";
import { StaticTypographyButtonProps } from "../ButtonWidget/ButtonComponent";
import { buttonColorStyles } from "../ButtonWidget/utils";
import { ButtonStyle } from "../Shared/Button";
import { sizeSection, visibleProperties } from "../basePropertySections";
import withMeta, { WithMeta } from "../withMeta";
import MenuComponent, { MenuComponentProps } from "./MenuComponent";
import { transformItem } from "./transform";
import { ManualMenuItem, MenuChildrenType } from "./types";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";

class MenuWidget extends BaseWidget<
  MenuWidgetProps,
  { isFocused: boolean; isOpen: boolean }
> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            hidden: () => true, // TODO: re-introduce this radio group when adding mapped children
            propertyName: "childrenType",
            label: "Items",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                value: MenuChildrenType.Manual,
                label: "Manual",
              },
              {
                value: MenuChildrenType.Mapped,
                label: "Mapped",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "manualChildren",
            label: "Children",
            headerControlType: "ADD_MENU_ITEM",
            controlType: "MENU_ITEMS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "animateLoading",
            label: "Loading animation",
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          ...visibleProperties({ useJsExpr: false }),
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
          },
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
      }),
      {
        sectionName: "Appearance",
        children: [
          ...buttonColorStyles("button"),
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "buttonStyle",
            label: "Button style",
            controlType: "DROP_DOWN",
            helpText: "Changes the style of the button",
            options: [
              {
                label: "Primary button",
                value: "PRIMARY_BUTTON",
              },
              {
                label: "Secondary button",
                value: "SECONDARY_BUTTON",
              },
              {
                label: "Tertiary button",
                value: "TERTIARY_BUTTON",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "buttonIcon",
            label: "Icon",
            helpText: "Select an icon for the button",
            controlType: "ICON_SELECTOR",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            customJSControl: "CODE_EDITOR_ICON_SELECTOR",
          },
          {
            propertyName: "buttonIconPosition",
            helpText: "Sets the alignment of the icon",
            label: "Icon position",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: "ICON_LEFT_ALIGN",
                value: "LEFT",
                label: "Left",
              },
              {
                icon: "ICON_RIGHT_ALIGN",
                value: "RIGHT",
                label: "Right",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: ({ buttonIcon }: MenuWidgetProps) => !buttonIcon,
          },
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
      animateLoading: VALIDATION_TYPES.BOOLEAN,

      // Button props
      buttonStyle: VALIDATION_TYPES.TEXT,
      buttonIcon: VALIDATION_TYPES.ICONS,
    };
  }

  private getMenuItems() {
    if (this.props.childrenType !== MenuChildrenType.Manual) {
      throw new Error(`Non-manual children types are implemented yet`);
    }

    return (this.props.manualChildren || []).map((child) =>
      transformItem(child),
    );
  }

  getPageView() {
    const buttonProps: MenuComponentProps["button"] = {
      textColor: this.props.buttonTextColor,
      backgroundColor: this.props.buttonBackgroundColor,
      buttonStyle: this.props.buttonStyle,
      icon: this.props.buttonIcon,
      iconPosition: this.props.buttonIconPosition,
      width: this.props.width,
      maxWidth: this.props.maxWidth,
      textProps: StaticTypographyButtonProps,
    };

    return (
      <MenuComponent
        items={this.getMenuItems()}
        label={this.props.label}
        isDisabled={this.props.isDisabled}
        button={buttonProps}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "MENU_WIDGET";
  }
}

export interface MenuWidgetProps extends WidgetPropsRuntime, WithMeta {
  label?: string;
  isDisabled?: boolean;
  isVisible?: boolean;
  childrenType: MenuChildrenType;
  manualChildren?: ManualMenuItem[];

  // Button props
  buttonTextColor: string;
  buttonBackgroundColor: string;
  buttonStyle?: ButtonStyle;
  buttonIcon?: string;
  buttonIconPosition?: "LEFT" | "RIGHT";
}

export default MenuWidget;
export const ConnectedMenuWidget = withMeta(MenuWidget);
