import { ApplicationScope } from "@superblocksteam/shared";
import { get, omit } from "lodash";
import React from "react";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { DATE_INPUT_FORMATS } from "legacy/utils/FormatUtils";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { getParentPath } from "utils/dottedPaths";
import { sendInfoUINotification } from "utils/notification";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import ChatComponent from "./ChatComponent";
import { ChatWidgetProps, UserMessageObject } from "./Constants";
import type { DerivedPropertiesMap } from "../Factory";

const PREFERRED_MESSAGE_FIELDS = ["text", "content", "message"];
const PREFERRED_NAME_FIELDS = [
  "name",
  "role",
  "id",
  "user",
  "speaker",
  "sender",
];
const PREFERRED_AVATAR_FIELDS = ["avatar", "image", "photo"];
const PREFERRED_DATE_FIELDS = ["date", "time", "timestamp"];
class ChatWidget extends BaseWidget<
  WithMeta & ChatWidgetProps,
  WidgetState & {
    pendingMessages: Record<string, UserMessageObject>;
    hasLoaded: boolean;
  }
> {
  state = {
    pendingMessages: {},
    hasLoaded: false,
  };

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "header",
            label: "Header",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter header text",
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: "Chat",
            visibility: "SHOW_NAME",
            isRemovable: true,
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "headerProps",
            propertyNameForHumans: "Header",
            hiddenIfPropertyNameIsNullOrFalse: "header",
          }),
          {
            propertyName: "placeholderText",
            label: "Placeholder text",
            helpText: "Sets the placeholder text for the chat input",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter placeholder text",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "messageHistory",
            label: "Message history",
            helpText:
              "Array containing the message history. Each object in the array represents a message.",
            controlType: "INPUT_TEXT",
            placeholderText: `[{ name: "", text: "" }]`,
            forceVertical: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Field name to use to access the message",
            propertyName: "messageField",
            label: "Message",
            controlType: "OBJECT_INDEXER_CONTROL",
            isBindProperty: true,
            isTriggerProperty: false,
            fieldToGetKeysOf: "messageHistory",
            preferredOptions: PREFERRED_MESSAGE_FIELDS,
            isRequired: true,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Field name to use to access the user's display name",
            propertyName: "displayNameField",
            label: "Name",
            controlType: "OBJECT_INDEXER_CONTROL",
            fieldToGetKeysOf: "messageHistory",
            isBindProperty: true,
            isTriggerProperty: false,
            preferredOptions: PREFERRED_NAME_FIELDS,
            isRequired: true,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Field name to use to access the avatar image URL",
            propertyName: "avatarField",
            label: "Avatar URL",
            controlType: "OBJECT_INDEXER_CONTROL",
            fieldToGetKeysOf: "messageHistory",
            isBindProperty: true,
            isTriggerProperty: false,
            preferredOptions: PREFERRED_AVATAR_FIELDS,
            isRequired: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Field name to use to access the timestamp of a message",
            propertyName: "timestampField",
            label: "Timestamp",
            controlType: "OBJECT_INDEXER_CONTROL",
            fieldToGetKeysOf: "messageHistory",
            isBindProperty: true,
            isTriggerProperty: false,
            preferredOptions: PREFERRED_DATE_FIELDS,
            isRequired: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Sets the format of the timestamp",
            propertyName: "timestampFormat",
            label: "Timestamp format",
            controlType: "DROP_DOWN",
            options: [
              ...DATE_INPUT_FORMATS,
              { label: "hh:mm a", value: "hh:mm a" },
            ],
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "showPendingMessage",
            helpText:
              "While the onSendMessage action is running, show the message as pending",
            label: "Show pending message",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "enablePendingState",
            helpText:
              "Enables the pending state for the chat widget after a message has been sent",
            label: "Enable pending state",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "pendingStateText",
            helpText: "Sets the text for the pending state",
            label: "Pending state text",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter text",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            hidden: (props: ChatWidgetProps, propertyPath: string) => {
              const parentPath = getParentPath(propertyPath);
              const enablePendingState = get(
                props,
                `${
                  parentPath
                    ? `${parentPath}.enablePendingState`
                    : "enablePendingState"
                }`,
                "",
              );
              return !enablePendingState;
            },
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "pendingStateTimeout",
            helpText:
              "Length of time between sending the message and showing the pending state in seconds",
            label: "Pending state timeout",
            controlType: "TIMER_INTERVAL",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            minValue: 0,
            hidden: (props: ChatWidgetProps, propertyPath: string) => {
              const parentPath = getParentPath(propertyPath);
              const enablePendingState = get(
                props,
                `${
                  parentPath
                    ? `${parentPath}.enablePendingState`
                    : "enablePendingState"
                }`,
                "",
              );
              return !enablePendingState;
            },
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            helpText: "Allow users to search for messages",
            propertyName: "enableChatSearch",
            label: "Search",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: "Hitting enter will send the message",
            propertyName: "enableEnterToSend",
            label: "Send on 'Enter'",
            controlType: "SWITCH",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: "Show emoji menu in editor.",
            propertyName: "enableEmojis",
            label: "Allow emojis",
            controlType: "SWITCH",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            helpText: "Allow users to add images to their messages",
            propertyName: "enableImages",
            label: "Allow images",
            controlType: "SWITCH",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          // TODO: https://superblocks.clickup.com/t/863g8ph3w
          // {
          //   propertyName: "isDisabled",
          //   helpText: "Controls whether a user can type into the chat",
          //   label: "Disabled",
          //   controlType: "SWITCH",
          //   isJSConvertible: true,
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          // },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection(),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [getPopoverConfig("onMessageSend", "")],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      messageHistory: VALIDATION_TYPES.TABLE_DATA,
      placeholderText: VALIDATION_TYPES.TEXT,
      header: VALIDATION_TYPES.TEXT,
      showPendingMessage: VALIDATION_TYPES.BOOLEAN,
      pendingStateText: VALIDATION_TYPES.TEXT,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      lastMessage: `{{ this.messageHistory && this.messageHistory.length > 0 ? this.messageHistory[this.messageHistory.length - 1] : undefined }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      userMessageText: "",
      userMessageRichText: "",
      userMessageImages: undefined,
    };
  }

  componentDidUpdate(prevProps: ChatWidgetProps) {
    if (!this.props.isLoading && !this.state.hasLoaded) {
      // for the chat component, we only want to show a loading state on the first load, not when
      // the message history is updated after a message is sent
      this.setState({ hasLoaded: true });
    }
  }

  handleSend = (
    message: UserMessageObject,
    callbackFn: (success: boolean) => void,
  ) => {
    const eventHandlerConfigured =
      this.props.onMessageSend != null &&
      this.props.onMessageSend.some((event) => (event as any).type != null);
    if (eventHandlerConfigured) {
      const msgKey = Date.now().toString();
      this.setState({
        pendingMessages: {
          ...this.state.pendingMessages,
          [msgKey]: message,
        },
      });
      this.props.updateWidgetMetaProperty("userMessageImages", message.images);
      this.props.updateWidgetMetaProperty(
        "userMessageRichText",
        message.richText,
      );
      const callbackId = addNewPromise((result: { success: boolean }) => {
        this.setState({
          pendingMessages: omit(this.state.pendingMessages, msgKey),
        });
        this.props.updateWidgetMetaProperty("userMessageText", "");
        this.props.updateWidgetMetaProperty("userMessageRichText", "");
        this.props.updateWidgetMetaProperty("userMessageImages", undefined);
        callbackFn(result.success);
      });
      this.props.updateWidgetMetaProperty(
        "userMessageText",
        message.plainText,
        createRunEventHandlersPayloadOptional({
          steps: this.props.onMessageSend,
          type: EventType.ON_CHAT_MESSAGE_SEND,
          currentScope: ApplicationScope.PAGE,
          callbackId,
          entityName: this.props.widgetName,
        }),
      );
    } else if (this.props.appMode === APP_MODE.EDIT) {
      sendInfoUINotification({
        message: `${this.props.widgetName} does not have an onMessageSend event handler`,
        duration: 2,
      });
      callbackFn(false); // this will put the message back into the input box
    }
  };

  getFieldName = (
    fieldName: string,
    preferredOptions: Array<string>,
    options: Array<string>,
    isRequired: boolean,
  ) => {
    if (fieldName || !isRequired) {
      return fieldName;
    }
    if (preferredOptions.length) {
      // look for prefered options in options, in order of preference
      for (let i = 0; i < preferredOptions.length; i++) {
        const bestMatch = options.find((opt) =>
          opt.toLowerCase().includes(preferredOptions[i]),
        );
        if (bestMatch) {
          return bestMatch;
        }
      }
    } else if (isRequired) {
      return options[0];
    }
    return "";
  };

  getFieldNames = () => {
    const {
      messageField,
      displayNameField,
      timestampField,
      avatarField,
      messageHistory,
    } = this.props;
    if (
      !this.props.messageHistory ||
      !Array.isArray(
        this.props.messageHistory || this.props.messageHistory.length === 0,
      )
    ) {
      return {
        messageField,
        displayNameField,
        timestampField,
        avatarField,
      };
    }
    if (typeof messageHistory[0] === "object") {
      const options = Object.keys(messageHistory[0]);

      return {
        messageField: this.getFieldName(
          messageField,
          PREFERRED_MESSAGE_FIELDS,
          options,
          true,
        ),
        displayNameField: this.getFieldName(
          displayNameField,
          PREFERRED_NAME_FIELDS,
          options,
          true,
        ),
        timestampField: this.getFieldName(
          timestampField,
          PREFERRED_DATE_FIELDS,
          options,
          false,
        ),
        avatarField: this.getFieldName(
          avatarField,
          PREFERRED_AVATAR_FIELDS,
          options,
          false,
        ),
      };
    }
    return {
      messageField,
      displayNameField,
      timestampField,
      avatarField,
    };
  };

  onMessageChange = ({ plainText, richText, images }: UserMessageObject) => {
    this.props.updateWidgetMetaProperty("userMessageText", plainText);
    this.props.updateWidgetMetaProperty("userMessageRichText", richText);
    this.props.updateWidgetMetaProperty("userMessageImages", images);
  };

  getPageView() {
    const { messageField, displayNameField, timestampField, avatarField } =
      this.getFieldNames();
    const { componentWidth } = getComponentDimensions(this.props);
    return (
      <ChatComponent
        messages={
          Array.isArray(this.props.messageHistory)
            ? this.props.messageHistory
            : []
        }
        avatarField={avatarField}
        timestampField={timestampField}
        displayNameField={displayNameField}
        timestampFormat={this.props.timestampFormat}
        messageField={messageField}
        onMessageSend={this.handleSend}
        enableImages={this.props.enableImages}
        enableEnterToSend={this.props.enableEnterToSend}
        enableSearch={this.props.enableChatSearch}
        enableEmojis={this.props.enableEmojis}
        placeholderText={this.props.placeholderText}
        header={this.props.header}
        showPendingState={this.props.enablePendingState}
        pendingStateText={this.props.pendingStateText}
        pendingStateTimeout={this.props.pendingStateTimeout}
        pendingMessages={
          this.props.showPendingMessage ? this.state.pendingMessages : {}
        }
        onMessageChange={this.onMessageChange}
        isLoading={!this.state.hasLoaded && this.props.isLoading}
        isDisabled={this.props.isDisabled === true}
        width={componentWidth}
        headerProps={this.props.headerProps}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "CHAT_WIDGET";
  }
}

export default ChatWidget;
export const ConnectedChatWidget = withMeta(ChatWidget);
