import { debounce } from "lodash";
import React from "react";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  WidgetType,
  CodeWidgetLanguages,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import withMeta from "../withMeta";
import { CodeComponentWithManagedLayout } from "./CodeEditor";
import { DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { CodeWidgetProps } from "./types";
import type { DerivedPropertiesMap } from "../Factory";

class CodeWidget extends BaseWidget<CodeWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Sets a label text for the code editor",
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            propertyNameForHumans: "Label",
            hiddenIfPropertyNameIsNullOrFalse: "label",
            textStyleParentDottedPath: "labelProps",
            defaultVariant: DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
          }),
          {
            propertyName: "mode",
            label: "Language",
            controlType: "DROP_DOWN",
            helpText: "Language ",
            options: [
              {
                label: "HJSON (human readable)",
                value: CodeWidgetLanguages.HJSON,
              },
              {
                label: "JSON (strict)",
                value: CodeWidgetLanguages.JSON,
              },
              {
                label: "Ruby HTML Template (ERB)",
                value: CodeWidgetLanguages.RUBY_ERB,
              },
              {
                label: "HTML",
                value: CodeWidgetLanguages.HTML,
              },
              {
                label: "Markdown",
                value: CodeWidgetLanguages.MARKDOWN,
              },
              {
                label: "Text",
                value: CodeWidgetLanguages.TEXT,
              },
              {
                label: "Python",
                value: CodeWidgetLanguages.PYTHON,
              },
              {
                label: "JavaScript",
                value: CodeWidgetLanguages.JAVASCRIPT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "initialValue",
            label: "Default value",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter code",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
        ],
      },
      sizeSection({
        heightSupportsFitContent: true,
      }),
      {
        sectionName: "Advanced",
        children: [
          {
            propertyName: "newlineCharacter",
            label: "Newline char",
            controlType: "DROP_DOWN",
            helpText:
              "Determines the newline character used in the code editor and its output",
            options: [
              {
                label: "AUTO",
                value: undefined,
              },
              {
                label: "LF (Unix, Linux, Mac)",
                value: "\n",
              },
              {
                label: "CR LF (Windows)",
                value: "\r\n",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "lineWrapping",
            label: "Line wrapping",
            helpText:
              "Whether Code Editor should scroll or wrap for long lines",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "showLineNumbers",
            label: "Line numbers",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: true, // Show this switch as ON for code editors created before this property was introduced
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "animateLoading",
            label: "Loading animation",
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
          {
            propertyName: "readOnly",
            label: "Read only",
            helpText: "Prevents editing",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
        ],
      },
      {
        sectionName: "Validation",
        sectionCategory: PropsPanelCategory.Interaction,
        children: [
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes input to the component mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "customValidationRule",
            label: "Validation",
            helpText: "Sets a custom validation rule for the code editor",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter validation rule",
            isBindProperty: true,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onChange",
            "Triggers an action when the check state is changed",
          ),
        ],
      },
    ];
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      stringValue: "initialValue",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      _parsedInfo: /*javascript*/ `{{(() => {
        const input = this.stringValue ?? this.initialValue;
        let parsedValue;
        let isValid = true;
        try {
          if (this.mode === "application/hjson") {
            parsedValue = HJSON.parse(input);
          } else if (this.mode === "application/json") {
            parsedValue = JSON.parse(input);
          } else {
            parsedValue = input;
          }
        } catch (e) {
          isValid = false;
        }
        if (this.isRequired && !input) isValid = false;
        if (this.customValidationRule === "false") {
          isValid = false;
        }
        return { parsedValue, isValid };
      })()}}`,
      parsedValue: /*javascript*/ `{{this._parsedInfo.parsedValue}}`,
      isValid: /*javascript*/ `{{this._parsedInfo.isValid}}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      stringValue: undefined,
    };
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      initialValue: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
    };
  }

  getPageView() {
    return (
      <CodeComponentWithManagedLayout
        {...this.props}
        onChange={this.onChange}
      />
    );
  }

  componentWillUnmount() {
    this.onChangeDebounced.flush();
  }

  onChangeDebounced = debounce((value: string) => {
    if (this.props.onChange) {
      this.runEventHandlers?.({
        steps: this.props.onChange,
        type: EventType.ON_TEXT_CHANGE,
        additionalNamedArguments: {},
      });
    }
  }, 300);

  onChange = (value: string) => {
    this.props.updateWidgetMetaProperty("stringValue", value);
    this.onChangeDebounced(value);
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.CODE_WIDGET;
  }
}

export default CodeWidget;
export const ConnectedCodeWidget = withMeta(CodeWidget);
