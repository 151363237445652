import { get } from "lodash";
import React from "react";
import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, VerticalAlign } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import {
  DATE_INPUT_FORMATS,
  DATE_OUTPUT_FORMATS,
  NUMBER_FORMATTING_OPTIONS,
  formatCurrency,
  formatDate,
  formatNumber,
} from "legacy/utils/FormatUtils";
import {
  customStylesProperties,
  textColorProperty,
  textStyleProperty,
} from "legacy/widgets/styleProperties";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { Flag } from "store/slices/featureFlags";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { DerivedPropertiesMap } from "../Factory";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { TextComponentWithLayoutManaged } from "./TextComponent";
import {
  TextStyle,
  TextWidgetProps,
  legacyTextWidgetTextStyleToTypographyMap,
  getTextStyleVariant,
  DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
} from "./index";

const THEME_STYLE_DEFAULTS = {
  bodyTextColor: "colors.neutral700",
  headerTextColor: "colors.neutral900",
};

class TextWidget extends BaseWidget<TextWidgetProps, WidgetState> {
  previousText?: string;
  static getPropertyPaneConfig(): PropertyPaneConfig<TextWidgetProps>[] {
    return [
      {
        sectionName: "General",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "text",
            helpText: "The value to display",
            label: "Text",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter text or <HTML/>",
            forceVertical: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "textType",
            helpText: "The data type of the value being displayed",
            label: "Data type",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Plain text",
                value: "text",
              },
              {
                label: "Number",
                value: "number",
              },
              {
                label: "Currency",
                value: "currency",
              },
              {
                label: "Date",
                value: "date",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "currency",
            label: "Currency Code",
            helpText: "The three letter ISO 4217 currency code",
            controlType: "DROP_DOWN",
            hidden: (props: TextWidgetProps) => props.textType !== "currency",
            defaultValue: "USD",
            options: CurrencyList.map((item) => ({
              label: item,
              value: item,
            })),
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "notation",
            label: "Number format",
            controlType: "DROP_DOWN",
            hidden: (props: TextWidgetProps) =>
              props.textType !== "number" && props.textType !== "currency",
            defaultValue: "standard",
            options: NUMBER_FORMATTING_OPTIONS,
            isBindProperty: false,
            isTriggerProperty: false,
            helpText: "The display format of the number",
          },
          {
            propertyName: "minimumFractionDigits",
            label: "Min decimals",
            helpText:
              "The minimum number of digits after the decimal separator",
            controlType: "INPUT_TEXT",
            hidden: (props: TextWidgetProps) =>
              props.textType !== "number" && props.textType !== "currency",
            isBindProperty: true,
            isTriggerProperty: false,
            placeholderText: "Input a number between 0-20",
          },
          {
            propertyName: "maximumFractionDigits",
            label: "Max decimals",
            helpText:
              "The maximum number of digits after the decimal separator",
            controlType: "INPUT_TEXT",
            hidden: (props: TextWidgetProps) =>
              props.textType !== "number" && props.textType !== "currency",
            isBindProperty: true,
            isTriggerProperty: false,
            placeholderText: "Input a number between 0-20",
          },
          {
            propertyName: "dateInputFormat",
            label: "Value format",
            controlType: "DROP_DOWN",
            options: DATE_INPUT_FORMATS,
            hidden: (props: TextWidgetProps) => props.textType !== "date",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "dateOutputFormat",
            label: "Display format",
            controlType: "DROP_DOWN",
            options: DATE_OUTPUT_FORMATS,
            hidden: (props: TextWidgetProps) => props.textType !== "date",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "textStyle",
            propertyCategory: PropsPanelCategory.Appearance,
            helpText: "Sets the font and style of the text",
            label: "Text type",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Heading 1",
                style: {
                  fontSize: 22,
                  fontWeight: 700,
                  lineHeight: 1.5715,
                },
                value: "HEADING_1",
              },
              {
                label: "Heading 2",
                style: {
                  fontSize: 18,
                  fontWeight: 700,
                  lineHeight: 1.5715,
                },
                value: "HEADING_2",
              },
              {
                label: "Label",
                style: {
                  fontSize: 14,
                  fontWeight: 700,
                  lineHeight: 1.5715,
                },
                value: "LABEL",
              },
              {
                label: "Normal text",
                style: {
                  fontSize: 14,
                  lineHeight: 1.5715,
                },
                value: "NORMAL_TEXT",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props, path, flags, additionalHiddenData) => {
              return Boolean(flags[Flag.ENABLE_TYPOGRAPHY]) === true;
            },
          },
          textStyleProperty({
            label: "Style",
            helpText: "The typography of the text",
            textStyleParentDottedPath: "textProps",
            defaultValueFn: ({ props }: { props: any }) => {
              if (
                props.textStyle != null &&
                props.textProps?.textStyle == null
              ) {
                return legacyTextWidgetTextStyleToTypographyMap[
                  props.textStyle as TextStyle
                ];
              }
              return DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT;
            },
          }),
          {
            key: "textColor-body",
            propertyName: "textColor",
            propertyCategory: PropsPanelCategory.Appearance,
            label: "Color",
            controlType: "COLOR_PICKER",
            themeValue: THEME_STYLE_DEFAULTS.bodyTextColor,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props, path, flags) => {
              const isHeaderType =
                props.textStyle === "HEADING_1" ||
                props.textStyle === "HEADING_2";
              return flags[Flag.ENABLE_TYPOGRAPHY] ? true : isHeaderType;
            },
          },
          {
            key: "textColor-header",
            propertyName: "textColor",
            propertyCategory: PropsPanelCategory.Appearance,
            label: "Text color",
            controlType: "COLOR_PICKER",
            themeValue: THEME_STYLE_DEFAULTS.headerTextColor,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props, path, flags) => {
              const isHeaderType =
                props.textStyle === "HEADING_1" ||
                props.textStyle === "HEADING_2";
              return flags[Flag.ENABLE_TYPOGRAPHY] ? true : !isHeaderType;
            },
          },
          textColorProperty({
            textStyleParentDottedPath: "textProps",
            isJSConvertible: true,
            updateHook: (
              props: TextWidgetProps,
              propertyPath: string,
              propertyValue: any,
            ) => {
              // Keep the legacy props.textColor in sync with textProps.textColor.default so that if
              // the typography flag is turned on and then off, the text component's color will not changed
              return [
                {
                  propertyPath: "textColor",
                  propertyValue,
                },
              ];
            },
            defaultValueFn: ({ props }) => {
              if (
                props.textProps?.textStyle?.textColor?.default == null &&
                props.textColor != null
              ) {
                return props.textColor;
              }
              return null; // let color picker figure it out
            },
            themeValue: ({ theme, props }) => {
              let variant = getTextStyleVariant(props, true);

              if (!variant) {
                variant = getTextStyleVariant(props, false);
              }
              if (variant === SB_CUSTOM_TEXT_STYLE) {
                return undefined;
              }
              // check if a custom variant has been deleted, if so fallback
              if (variant && !get(theme.typographies, variant)) {
                variant = DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT;
              }

              return {
                value: `typographies.${variant}.textColor.default`,
                treatAsNull: false,
              };
            },
          }),
          ...customStylesProperties<"textProps">({
            textStyleParentDottedPath: "textProps",
          }),
        ],
      },
      sizeSection({
        heightSupportsFitContent: true,
        widthSupportsFitContent: true,
      }),
      {
        sectionName: "Icons",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [
          {
            propertyName: "icon",
            label: "Icon",
            helpText: "Select an icon",
            controlType: "ICON_SELECTOR",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            customJSControl: "CODE_EDITOR_ICON_SELECTOR",
          },
          {
            propertyName: "iconPosition",
            helpText: "The alignment of the icon",
            label: "Icon position",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: "ICON_LEFT_ALIGN",
                value: "LEFT",
                label: "Left",
              },
              {
                icon: "ICON_RIGHT_ALIGN",
                value: "RIGHT",
                label: "Right",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            defaultValue: "LEFT",
            hidden: ({ icon }: TextWidgetProps) => !icon,
          },
        ],
      },
      {
        sectionName: "Style",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [
          {
            propertyName: "textAlign",
            helpText:
              "The horizontal alignment of the text relative to the component width",
            label: "Horizontal align",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: "LEFT_ALIGN",
                value: "LEFT",
              },
              {
                icon: "CENTER_ALIGN",
                value: "CENTER",
              },
              {
                icon: "RIGHT_ALIGN",
                value: "RIGHT",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "verticalAlign",
            helpText:
              "The vertical alignment of the text relative to the component height",
            label: "Vertical align",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: "VERTICAL_AUTO",
                value: VerticalAlign.AUTO,
              },
              {
                icon: "VERTICAL_TOP",
                value: VerticalAlign.TOP,
              },
              {
                icon: "VERTICAL_CENTER",
                value: VerticalAlign.CENTER,
              },
              {
                icon: "VERTICAL_BOTTOM",
                value: VerticalAlign.BOTTOM,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: TextWidgetProps) =>
              props.height?.mode === "fitContent",
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          ...visibleProperties({ useJsExpr: false }),
          {
            propertyName: "shouldScroll",
            helpText:
              "Whether the user can scroll vertically or horizontally to acess any overflow content. If disabled, content will clip",
            propertyCategory: PropsPanelCategory.Layout,
            label: "Scroll overflow",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      text: VALIDATION_TYPES.TEXT,
      textStyle: VALIDATION_TYPES.TEXT,
      textType: VALIDATION_TYPES.TEXT,
      shouldScroll: VALIDATION_TYPES.BOOLEAN,
      minimumFractionDigits: VALIDATION_TYPES.FRACTION_DIGITS,
      maximumFractionDigits: VALIDATION_TYPES.FRACTION_DIGITS,
      icon: VALIDATION_TYPES.ICONS,
    };
  }

  componentDidUpdate(prevProps: TextWidgetProps) {
    if (prevProps.text !== this.props.text) {
      // we don't need to rerender on previousText changing because it's only used in error logging
      this.previousText = prevProps.text;
    }
    super.componentDidUpdate(prevProps);
  }

  getText() {
    switch (this.props.textType) {
      case "number":
        return formatNumber(
          this.props.text,
          this.props.notation,
          this.props.minimumFractionDigits,
          this.props.maximumFractionDigits,
        );
      case "currency":
        return formatCurrency(
          this.props.text,
          this.props.currency,
          this.props.notation,
          this.props.minimumFractionDigits,
          this.props.maximumFractionDigits,
        );
      case "date":
        return formatDate(
          this.props.text,
          this.props.dateInputFormat,
          this.props.dateOutputFormat,
        );
      default:
        return this.props.text;
    }
  }

  getErrorInformation() {
    return {
      text: this.props.text,
      previousText: this.previousText,
    };
  }

  getPageView() {
    return <TextComponentWithLayoutManaged {...this.props} />;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.text }}`,
    };
  }

  getWidgetType(): WidgetType {
    return "TEXT_WIDGET";
  }
}

export default TextWidget;
