import equal from "@superblocksteam/fast-deep-equal/es6";
import { type FileMetadataPrivate } from "@superblocksteam/shared";
import { UppyOptions, UppyFile } from "@uppy/core";
import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { css } from "styled-components";
import Skeleton from "legacy/components/utils/Skeleton";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { type WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import withMeta, { WithMeta } from "../withMeta";
import { UppyManager, FileManager } from "./FilePickerSingleton";
import { FilepickerComponent } from "./FilepickerComponent";
import type { DerivedPropertiesMap } from "../Factory";
import type { AppState } from "store/types";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

// Max file size is 500Mb, also set in nginx config for the cloud agent
// For on-prem agents this is done without nginx
const LIMIT_MAX_FILE_SIZE_MB = 500;

/* Forces the file type info to be shown to the user */
css`
  .uppy-Dashboard-AddFiles-info {
    display: block !important;
  }
`;

class FilePickerWidget extends BaseWidget<
  FilePickerWidgetProps,
  FilePickerWidgetState
> {
  constructor(props: FilePickerWidgetProps) {
    super(props);

    if (!UppyManager.get(this.props.widgetId)) {
      UppyManager.create(this.props.widgetId, this.getUppyOptions());
    } else {
      this.conditionallyUpdateOptions();
    }
    this.conditionallyResetFiles();
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "selectionType",
            label: "Selection type",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Single select",
                value: 1,
              },
              {
                label: "Multi select",
                value: undefined,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "allowedFileTypes",
            helpText:
              "File types can either be mime types or suffixes. * is wildcard.",
            label: "Allowed file types",
            controlType: "INPUT_TEXT",
            placeholderText: "['image/*', '.csv', '.zip']",
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes input to the component mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "animateLoading",
            label: "Loading animation",
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection({ heightSupportsFitContent: true }),
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      allowedFileTypes: VALIDATION_TYPES.ARRAY,
      files: VALIDATION_TYPES.ARRAY,
      isRequired: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      isValid: `{{ this.isRequired ? this.files && this.files.length > 0 : true }}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      files: [],
      isTouched: false,
    };
  }

  getUppyOptions(): UppyOptions {
    return {
      id: this.props.widgetId,
      autoProceed: false,
      allowMultipleUploadBatches: true,
      debug: false,
      restrictions: {
        // 1 Gb for all files
        maxTotalFileSize: LIMIT_MAX_FILE_SIZE_MB * 1024 * 1024,
        maxFileSize:
          Math.min(this.props.maxFileSize ?? Infinity, LIMIT_MAX_FILE_SIZE_MB) *
          1024 *
          1024,
        maxNumberOfFiles: this.props.selectionType,
        minNumberOfFiles: 0,
        allowedFileTypes:
          this.props.allowedFileTypes &&
          (this.props.allowedFileTypes.includes("*") ||
            isEmpty(this.props.allowedFileTypes))
            ? null
            : this.props.allowedFileTypes,
      },
    };
  }

  conditionallyUpdateOptions = () => {
    const uppy = UppyManager.get(this.props.widgetId);
    const newOptions = this.getUppyOptions();
    if (!equal(newOptions, uppy.getOptions())) {
      uppy.setOptions(newOptions);
    }
  };

  conditionallyResetFiles = () => {
    const uppy = UppyManager.get(this.props.widgetId);
    const noFiles = !this.props.files?.length;
    const uppyHasFiles = uppy.getFiles().length !== 0;
    if (noFiles && uppyHasFiles) {
      FileManager.deleteFiles(this.props.widgetName);
      uppy.cancelAll?.();
    }
  };

  onFileRemoved = (file: any, reason: string) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    if (reason === "cancel-all") {
      // Cancelling is triggered once per file by Uppy, so this
      // does create extra actions in Redux. This will not create
      // extra evaluations because of the debounced evaluator.
      this.props.updateWidgetMetaProperty("files", []);
      FileManager.deleteFiles(this.props.widgetName);
      return;
    }

    const cleanId = file.id.replaceAll("/", "_");
    const updatedFiles =
      this.props.files?.filter(
        (dslFile) => cleanId !== dslFile.$superblocksId,
      ) ?? [];
    this.props.updateWidgetMetaProperty("files", updatedFiles);
    FileManager.deleteFile(this.props.widgetName, cleanId);
  };

  onFilesAdded = (files: UppyFile[]) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    const dslFiles = this.props.files ? [...this.props.files] : [];
    const mappedFiles = files
      .map((f) => {
        const cleanId = f.id.replaceAll("/", "_");

        if (
          this.props.files?.find(
            (otherFile) => otherFile.$superblocksId === cleanId,
          )
        ) {
          return undefined;
        }

        FileManager.update(this.props.widgetName, cleanId, f.data as File);

        return {
          name: f.meta.name,
          extension: f.extension,
          type: f.type,
          size: f.size,
          encoding: "text",
          // Only for use in same browser session. Must be manually removed from memory.
          previewUrl: URL.createObjectURL(f.data),
          $superblocksId: cleanId,
        } as FileMetadataPrivate;
      })
      .filter(Boolean);

    this.props.updateWidgetMetaProperty("files", dslFiles.concat(mappedFiles));
  };

  private subscribeToFileListeners = () => {
    const uppy = UppyManager.get(this.props.widgetId);
    uppy.on("file-removed", this.onFileRemoved);
    uppy.on("files-added", this.onFilesAdded);
  };

  private unsubscribeToFileListeners = () => {
    const uppy = UppyManager.get(this.props.widgetId);
    uppy?.off("file-removed", this.onFileRemoved);
    uppy?.off("files-added", this.onFilesAdded);
  };

  componentDidUpdate(prevProps: FilePickerWidgetProps) {
    super.componentDidUpdate(prevProps);
    this.conditionallyUpdateOptions();
    this.conditionallyResetFiles();
    this.clearFilesFromMemory(prevProps.files);
  }

  // Reclaim the memory used by blobs.
  clearFilesFromMemory(previousFiles: any[] = []) {
    const { files = [] } = this.props;
    previousFiles.forEach((file: any) => {
      let { previewUrl } = file;
      if (typeof previewUrl === "string" && previewUrl.startsWith("blob:")) {
        if (files.findIndex((f: any) => f.previewUrl === previewUrl) === -1) {
          previewUrl = previewUrl.split("?")[0];
          URL.revokeObjectURL(previewUrl);
        }
      }
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.subscribeToFileListeners();
  }

  componentWillUnmount() {
    super.componentWillUnmount?.();
    this.unsubscribeToFileListeners();
  }

  getPageView() {
    if (this.props.isLoading) return <Skeleton />;
    // props.isValid will be updated AFTER files are updated
    const isValid = this.props.isRequired
      ? (this.props.files ?? [])?.length > 0
      : true;
    return (
      <FilepickerComponent
        {...this.props}
        isValid={isValid || !this.props.isTouched}
        uppy={UppyManager.get(this.props.widgetId)}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "FILE_PICKER_WIDGET";
  }
}

type FilePickerWidgetState = WidgetState;

export interface FilePickerWidgetProps extends WidgetPropsRuntime, WithMeta {
  selectionType?: number;
  files?: any[];
  maxFileSize?: number;
  allowedFileTypes: string[];
  isRequired?: boolean;
  isLoading: boolean;
  generatedTheme: GeneratedTheme;
  isTouched: boolean;
  isValid?: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    generatedTheme: selectGeneratedTheme(state),
  };
};

export default FilePickerWidget;
export const ConnectedFilePickerWidget = connect(mapStateToProps)(
  withMeta(FilePickerWidget),
);
